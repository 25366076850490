import React, { useState, useEffect } from 'react';
import AdminLayout from "./layout";
import AboutPageCard from '@/components/admin/AboutPageCard';
import AboutPageForm from '@/components/admin/AboutPageForm';
import axios from 'axios';


interface AboutPage {
  _id: string;
  title:string;
  position:number;
  description:string;
  image:string;
}

const About: React.FC = () => {
  const [aboutPages, setAboutPages] = useState<AboutPage[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [aboutPageIdToUpdate, setAboutPageIdToUpdate] = useState<string>('');



  const fetchAboutPages = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_KEY}/about`);
      setAboutPages(response.data);
    } catch (error) {
      console.error('Error fetching about pages:', error);
    }
  };

  useEffect(() => {
    fetchAboutPages();
    handleUpdateAboutPage;
  }, []);

  const handleAddAboutPage = () => {
    setIsFormOpen(true);
    setAboutPageIdToUpdate('');
  };

  const handleUpdateAboutPage = (id: string) => {
    setIsFormOpen(true);
    setAboutPageIdToUpdate(id);
  };


  const handleCloseForm = () => {
    setIsFormOpen(false);
    setAboutPageIdToUpdate('');
  };

  const handleDeleteAboutPage = () => {
    fetchAboutPages();
  };

  return (
    <AdminLayout>
      <div className="h-auto w-screen ">
        <div className="flex justify-between items-center mb-4">
          <button onClick={handleAddAboutPage} className="bg-white text-black px-4 py-2 rounded">
            Add New
          </button>
        </div>
        <div className="flex flex-wrap gap-4">
          {aboutPages.map((aboutPage) => (
            <AboutPageCard
              key={aboutPage._id}
              aboutPage={aboutPage}
              onUpdate={() => fetchAboutPages()}
              onDelete={() => handleDeleteAboutPage()}
            />
          ))}
        </div>
        <AboutPageForm
          isOpen={isFormOpen}
          onClose={handleCloseForm}
          onSubmitSuccess={() => {
            handleCloseForm();
            fetchAboutPages();
          }}
          initialValues={aboutPages.find(page => page._id === aboutPageIdToUpdate)}
          aboutPageIdToUpdate={aboutPageIdToUpdate}
        />
      </div>
    </AdminLayout>
  );
};

export default About;
