// src/components/admin/AboutPageForm.tsx

import React, { useState } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/ui/button';

import axios from 'axios';

interface AboutPageFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
  initialValues?: {
    image: string;
    title: string;
    description: string;
    position: number;
  };
  aboutPageIdToUpdate?: string;
}

const AboutPageForm: React.FC<AboutPageFormProps> = ({
  isOpen,
  onClose,
  onSubmitSuccess,
  initialValues = { image: '', title: '', description: '', position: 0 },
  aboutPageIdToUpdate,
}) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  
  const onSubmit = async (data: any) => {
    try {
      if (aboutPageIdToUpdate) {
        // Update existing about page
        if (selectedImage) {
          const formData = new FormData();
          formData.append('image', selectedImage);
          formData.append('title', data.title);
          formData.append('description', data.description);
          formData.append('position', data.position);
          await axios.put(`${import.meta.env.VITE_API_KEY}/about/${aboutPageIdToUpdate}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          await axios.put(`${import.meta.env.VITE_API_KEY}/about/${aboutPageIdToUpdate}`, data);
        }
      } else {
        // Create new about page
        if (selectedImage) {
          const formData = new FormData();
          formData.append('image', selectedImage);
          formData.append('title', data.title);
          formData.append('description', data.description);
          formData.append('position', data.position);
          await axios.post(`${import.meta.env.VITE_API_KEY}/about`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          await axios.post(`${import.meta.env.VITE_API_KEY}/about`, data);
        }
      }
      onSubmitSuccess();
      reset();
      onClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedImage(files[0]);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="z-10">
      <h2>{aboutPageIdToUpdate ? 'Update About Page' : 'Add About Page'}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="image">Select Image</label>
          <input
            id="image"
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            className="block w-full p-2 border border-gray-300 rounded"
          />
          {selectedImage && (
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              className="mt-2 mb-2 block max-w-full h-auto rounded-lg shadow-md"
            />
          )}
        </div>
        <div>
          <label htmlFor="title">Title</label>
          <input
            id="title"
            type="text"
            {...register('title', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            {...register('description', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="position">Position</label>
          <input
            id="position"
            type="number"
            {...register('position', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <Button type="submit">
          {aboutPageIdToUpdate ? 'Update' : 'Add'}
        </Button>
      </form>
    </Modal>
  );
};

export default AboutPageForm;
