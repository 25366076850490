import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface FAQItem {
  _id: string;
  question: string;
  answer: string;
  place: number;
}

const FAQ: React.FC = () => {
  const [faqs, setFaqs] = useState<FAQItem[]>([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_API_KEY}/faq`);
      if (!response.ok) {
        throw new Error('Failed to fetch FAQs');
      }
      const faqsData = await response.json() as FAQItem[];
      setFaqs(faqsData);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <div className='w-full h-[600px] channel mt-40 items-center gap-10 flex flex-col'>
      <h1 className='text-center  text-slate-100 p-4 text-7xl mid:text-3xl font-bold'>
     Frequently Asked Questions
      </h1>
      <Accordion type="single" collapsible className="w-5/6 flex font-bold  flex-col gap-2 text-white rounded-2xl">
        {faqs.map((faq) => (
          <AccordionItem  key={faq._id} value={`faq-${faq._id}`}>
            <AccordionTrigger className='small:text-sm'>{faq.question}</AccordionTrigger>
            <AccordionContent className='text-white text-sm'>
             {faq.answer}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default FAQ;
