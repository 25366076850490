import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../components/ui/card';
import { Button } from '../../components/ui/button';

interface ImageProps {
  url: string;
  public_id: string;
}

interface TeamMemberProps {
  _id: string;
  name: string;
  experience: number;
  title: string;
  image?: ImageProps;
  __v: number;
}

interface TeamCardProps {
  teamMember: TeamMemberProps;
  onUpdate: (teamMember: TeamMemberProps) => void;
  onDelete: (id: string) => void;
}

const TeamCard: React.FC<TeamCardProps> = ({ teamMember, onUpdate, onDelete }) => {
  return (
    <Card key={teamMember._id} className="w-full h-auto text-white max-w-md m-2">
      {teamMember.image && <img src={teamMember.image.url} alt={teamMember.name} className="rounded-t-lg" />}
      <CardHeader>
        <CardTitle>{teamMember.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription>Experience: {teamMember.experience} years</CardDescription>
        <CardDescription>Title: {teamMember.title}</CardDescription>
        <div className="flex justify-between mt-4">
          <Button onClick={() => onUpdate(teamMember)} variant="outline">
            Update
          </Button>
          <Button onClick={() => onDelete(teamMember._id)} variant="destructive" className="bg-black text-white">
            Delete
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default TeamCard;
