import { useState, useEffect } from 'react';
import { Card } from '../components/ui/card';
// import { Dialog, DialogContent, DialogOverlay, DialogTitle } from '../components/ui/dialog';
import axios from 'axios';
// import { FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';

interface GalleryImage {
  _id: string;
  image: {
    url: string;
    public_id: string;
  };
  heading: string;
  description: string;
  __v: number;
}

const Gallery = () => {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await axios.get<GalleryImage[]>(`${import.meta.env.VITE_API_KEY}/gallery`);
        setGalleryImages(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch gallery images');
        setLoading(false);
      }
    };

    fetchGalleryImages();
  }, []);

  // const openDialog = (index: number) => {
  //   setCurrentIndex(index);
  //   setIsOpen(true);
  // };

  // const closeDialog = () => {
  //   setIsOpen(false);
  // };

  // const showNextImage = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
  // };

  // const showPreviousImage = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex - 1 + galleryImages.length) % galleryImages.length);
  // };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen">{error}</div>;
  }

  return (
    <div>
      <section className="max-w-5xl channel w-full px-4 py-16">
        <h2 className="text-4xl font-bold mb-6 text-center">Gallery</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          {galleryImages.map((image, index) => (
            <Card
              key={image._id}
              className="bg-gray-800 cursor-pointer text-white"
             
            >
              <img
                src={image.image.url}
                alt={`Gallery Image ${index + 1}`}
                className="w-full h-60 object-cover"
              />
            </Card>
          ))}
        </div>
      </section>

      {/* <Dialog open={isOpen}>
        <DialogOverlay className="fixed inset-0 bg-black bg-opacity-70" onClick={closeDialog} />
        <DialogContent className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center w-4/5 h-4/5 bg-white rounded-lg overflow-hidden relative">
          <DialogTitle className="text-lg text-black font-bold mb-4">Image Preview</DialogTitle>
          <button
            onClick={closeDialog}
            className="absolute top-4 right-4 text-3xl text-black bg-gray-200 p-2 rounded-full hover:bg-gray-300 focus:outline-none"
          >
            <FaTimes />
          </button>
          <div className="relative w-full h-full flex items-center justify-center">
            <img
              src={galleryImages[currentIndex].image.url}
              alt="Selected Gallery"
              className="w-full h-full object-contain"
            />
            <button
              onClick={showPreviousImage}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-3xl text-white bg-black bg-opacity-50 p-3 rounded-full hover:bg-opacity-75 focus:outline-none"
            >
              <FaArrowLeft />
            </button>
            <button
              onClick={showNextImage}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-3xl text-white bg-black bg-opacity-50 p-3 rounded-full hover:bg-opacity-75 focus:outline-none"
            >
              <FaArrowRight />
            </button>
          </div>
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default Gallery;
