
import { Link } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
const Sidebar = () => {
  const links = [ 'Banner','channel','About', 'Gallery', 'Team','Faq'];
  const location = useLocation();

  const isActive = (path: string) => location.pathname.includes(path);

  return (
    <div className="w-64 h-screen bg-gray-900 shadow-md rounded-sm sticky top-0 text-white font-poppins font-bold p-6">
      <ul className="space-y-4">
        {links.map((link) => {
        
          return (
            <li key={link} className="text-center h-full ">
              <Link
                // className='block text-lg font-medium py-2 rounded transition duration-300'
                  // isActive ? 'bg-gray-700' : 'hover:bg-gray-700 hover:text-white'
                  className={`block text-lg font-medium py-2 rounded transition duration-300 ${isActive(link) ? 'bg-gray-700' : 'hover:bg-gray-700 hover:text-white'}`}
                
                to={`/admin/${link}`}
              >
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
