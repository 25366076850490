import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button } from '../../components/ui/button';

interface GalleryItemProps {
  _id: string;
  heading: string;
  description: string;
  image?: {
    url: string;
    public_id: string;
  };
}

interface GalleryFormProps {
  currentGalleryItem?: GalleryItemProps | null;
  onSubmit: (data: GalleryFormData) => void;
  onClose: () => void;
}

interface GalleryFormData {
  heading: string;
  description: string;
  image?: FileList;
}

const GalleryForm: React.FC<GalleryFormProps> = ({ currentGalleryItem, onSubmit, onClose }) => {
  const { register, handleSubmit, reset, setValue } = useForm<GalleryFormData>();

  useEffect(() => {
    if (currentGalleryItem) {
      setValue('heading', currentGalleryItem.heading);
      setValue('description', currentGalleryItem.description);
    } else {
      reset();
    }
  }, [currentGalleryItem, setValue, reset]);

  const onSubmitHandler: SubmitHandler<GalleryFormData> = (data) => {
    onSubmit(data);
  };

  return (
    <div className="z-20">
      <h2>{currentGalleryItem ? 'Update Gallery Item' : 'Add Gallery Item'}</h2>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-4">
        <div>
          <label htmlFor="heading">Title</label>
          <input
            id="heading"
            {...register('heading', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            {...register('description', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            {...register('image')}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <Button type="submit">
          {currentGalleryItem ? 'Update' : 'Add'}
        </Button>
        <Button type="button" onClick={onClose} variant="outline">
          Cancel
        </Button>
      </form>
    </div>
  );
};

export default GalleryForm;
