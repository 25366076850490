import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../components/ui/card';
import { Button } from '../../components/ui/button';

interface BannerProps {
  _id: string;
  heading: string;
  description: string;
  image?: {
    url: string;
    public_id: string;
  };
  __v: number;
}

interface BannerCardProps {
  banner: BannerProps;
  onUpdate: (banner: BannerProps) => void;
  onDelete: (id: string) => void;
}

const BannerCard: React.FC<BannerCardProps> = ({ banner, onUpdate, onDelete }) => {
  return (
    <Card key={banner._id} className="w-full h-auto text-white max-w-md m-2">
      {banner.image && <img src={banner.image.url} alt={banner.heading} className="rounded-t-lg" />}
      <CardHeader>
        <CardTitle>{banner.heading}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription>{banner.description}</CardDescription>
        <div className="flex justify-between mt-4">
          <Button onClick={() => onUpdate(banner)} variant="outline">
            Update
          </Button>
          <Button onClick={() => onDelete(banner._id)} variant="destructive" className="bg-black text-white">
            Delete
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default BannerCard;
