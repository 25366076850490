import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FaArrowCircleDown } from "react-icons/fa";
import {TPlaylistPageProps,TPlaylistItem} from '../utils/type'


const PlaylistPage: React.FC<TPlaylistPageProps> = ({ onSelectPlaylist }) => {
  const [playlists, setPlaylists] = useState<TPlaylistItem[]>([]);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/youtube/v3/playlists",
          {
            params: {
              part: "snippet",
              channelId: "UCJinKAyRTpHJOPE0YgCMhog",
              maxResults: 50,
              key: "AIzaSyAyFenBbqFFtTG3-vmSLjBbi8Jt1cCPaJk", // Use environment variable for API key
            },
          }
        );
        setPlaylists(response.data.items);
      } catch (error) {
        console.error("Error fetching the playlists:", error);
        setError("Failed to load playlists. Please try again later.");
      }
    };

    fetchPlaylists();
  }, []);

  return (
    <div className="w-screen h-auto p-6 my-10 flex flex-col  items-center ">
        <div className="h-28">
      <h1 className=" text-green-300 small:hidden mid:text-xl flex gap-3 text-5xl">Click here to watch videos <span><FaArrowCircleDown /></span></h1>
        </div>
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-6">
          {playlists.map((playlist) => (
            
            <Card
              key={playlist.id}
              className="w-56  hover:opacity-70 cursor-pointer h-72"
              onClick={() => onSelectPlaylist(playlist.id)}
            >
              <CardHeader className="p-0">
                <img
                  src={playlist.snippet.thumbnails.medium.url}
                  alt={playlist.snippet.title}
                  className="w-full h-32 object-cover"
                />
              </CardHeader>
              <CardContent className="flex flex-col items-center justify-center p-4 text-center">
                <CardTitle className="text-sm mid:text-[10px] text-blue-100 font-bold">
                  {playlist.snippet.title}
                </CardTitle>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlaylistPage;
