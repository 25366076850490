import React, { useEffect, useState } from "react";
import axios from "axios";
import { TVideosPageProps, TVideoItem } from "../utils/type";
import { Button } from "@/components/button";

const VideosPage: React.FC<TVideosPageProps> = ({ playlistId }) => {
  const [videos, setVideos] = useState<TVideoItem[]>([]);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideos = async (nextPageToken = "") => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/youtube/v3/playlistItems",
          {
            params: {
              part: "snippet",
              playlistId: playlistId,
              maxResults: 25,
              pageToken: nextPageToken,
              key: import.meta.env.VITE_YOUTUBE_API_KEY,
            },
          }
        );

        const videoItems = response.data.items;
        setVideos((prevVideos) => [...prevVideos, ...videoItems]);

        // If the nextPageToken exists, fetch the next page of videos
        if (response.data.nextPageToken) {
          fetchVideos(response.data.nextPageToken);
        } else {
          // Set the first video in the list as the selected video if not already set
          if (!selectedVideoId && videoItems.length > 0) {
            setSelectedVideoId(videoItems[0]?.snippet.resourceId.videoId || null);
          }
        }
      } catch (error) {
        return (
          <div className="w-screen z-10 h-screen flex flex-col justify-center items-center gap-10">
            <div className="font-bold text-white text-3xl">
              This playlist is not available here due to some technical issues
            </div>
            <div>
              <Button variant={"ghost"} className="bg-white text-black">
                Visit YouTube playlist to watch
              </Button>
            </div>
          </div>
        );
      }
    };

    // Clear videos and start fetching from the beginning
    setVideos([]);
    setError(null); // Reset error state
    fetchVideos();
  }, [playlistId]);

  return (
    <div className="w-full h-screen flex  flex-col-reverse lg:flex-row">
      <div className="w-full lg:w-1/4 h-1/2 lg:h-full p-6 bg-black overflow-y-auto space-y-6">
        {error ? (
          <div className="text-white text-center p-4">
            <p>{error}</p>
            <p>
              Watch the channel's playlist on YouTube:{" "}
              <a
                href={`https://www.youtube.com/playlist?list=${playlistId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline"
              >
                YouTube Playlist
              </a>
            </p>
          </div>
        ) : (
          videos.map((video) => {
            const thumbnailUrl = video.snippet.thumbnails?.medium?.url;
            return (
              <div
                key={video.snippet.resourceId.videoId}
                className={` h-40 overflow-hidden flex cursor-pointer   border border-gray-300 rounded-lg ${selectedVideoId === video.snippet.resourceId.videoId ? 'bg-slate-600' : ''}`}
                onClick={() => setSelectedVideoId(video.snippet.resourceId.videoId)}
              >
                <div className=" h-full">
                  {thumbnailUrl ? (
                    <img
                      src={thumbnailUrl}
                      alt={video.snippet.title}
                      className="w-32 h-40 rounded-lg"  
                    />
                  ) : (
                    <div className="w-32 h-40 bg-gray-700 rounded-lg flex items-center justify-center text-white">
                      No Image
                    </div>
                  )}
                </div>
                <div className="w-1/2 object-contain  ">
                  <h2 className="text-[10px]  pt-4 text-center mid:text-[8px]  text-blue-100 font-bold">
                    {video.snippet.title}
                  </h2>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="w-full lg:w-3/4 h-1/2 lg:h-full my-5 flex justify-center bg-transparent">
        {selectedVideoId && !error && (
          <iframe
            className="rounded-xl"
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${selectedVideoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default VideosPage;
