import React, { useEffect, useState } from 'react';
import AdminLayout from './layout';
import Modal from 'react-modal';
import TeamForm from '../components/admin/TeamForm';
import TeamCard from '../components/admin/TeamCard';
import { Button } from '../components/ui/button';
import axios from 'axios';

interface TeamMemberProps {
  _id: string;
  name: string;
  experience: number;
  title: string;
  image?: {
    url: string;
    public_id: string;
  };
  __v: number;
}

const Team: React.FC = () => {
  const [teamMembers, setTeamMembers] = useState<TeamMemberProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentTeamMember, setCurrentTeamMember] = useState<TeamMemberProps | null>(null);


  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
      
        const response = await axios.get<TeamMemberProps[]>(`${import.meta.env.VITE_API_KEY}/team`);
        setTeamMembers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch team members');
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, []);

  const openUpdateModal = (teamMember: TeamMemberProps) => {
    setCurrentTeamMember(teamMember);
    setModalIsOpen(true);
  };

  const handleUpdate = async (data: any) => {
    if (!currentTeamMember) return;

    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('experience', data.experience.toString());
      formData.append('title', data.title);
      if (data.image && data.image[0]) {
        formData.append('image', data.image[0]);
      }

      await axios.put(`${import.meta.env.VITE_API_KEY}/team/${currentTeamMember._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const response = await axios.get<TeamMemberProps[]>(`${import.meta.env.VITE_API_KEY}/team`);
      setTeamMembers(response.data);
      setModalIsOpen(false);
      setCurrentTeamMember(null);
    } catch (error) {
      console.error('Error updating team member:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${import.meta.env.VITE_API_KEY}/team/${id}`);
      const response = await axios.get<TeamMemberProps[]>(`${import.meta.env.VITE_API_KEY}/team`);
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error deleting team member:', error);
    }
  };

  const handleSubmit = async (data: any) => {
    if (currentTeamMember) {
      handleUpdate(data);
    } else {
      try {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('experience', data.experience.toString());
        formData.append('title', data.title);
        if (data.image && data.image[0]) {
          formData.append('image', data.image[0]);
        }

        await axios.post(`${import.meta.env.VITE_API_KEY}/team`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const response = await axios.get<TeamMemberProps[]>(`${import.meta.env.VITE_API_KEY}/team`);
        setTeamMembers(response.data);
        setModalIsOpen(false);
      } catch (error) {
        console.error('Error adding team member:', error);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <AdminLayout>
      <div className="flex flex-col w-full h-full text-white items-center space-y-4">
        <Button onClick={() => setModalIsOpen(true)} variant={'secondary'} className="bg-white text-black">
          Add Team Member
        </Button>
        <div className="w-full justify-center flex flex-wrap">
          {teamMembers.map((teamMember) => (
            <TeamCard key={teamMember._id} teamMember={teamMember} onUpdate={openUpdateModal} onDelete={handleDelete} />
          ))}
        </div>
      </div>

      <Modal
        className="z-10"
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setCurrentTeamMember(null);
        }}
        contentLabel="Add or Update Team Member Modal"
      >
        <TeamForm
          currentTeamMember={currentTeamMember}
          onSubmit={handleSubmit}
          onClose={() => setModalIsOpen(false)}
        />
      </Modal>
    </AdminLayout>
  );
};

export default Team;
