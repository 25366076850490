import Gallery from '../components/Gallery';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface About {
  image: string;
  title: string;
  description: string;
  position: number;
}

export default function About() {
  const [about, setAbout] = useState<About[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get<About[]>(`${import.meta.env.VITE_API_KEY}/about`);
        setAbout(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch about information');
        setLoading(false);
      }
    };

    fetchAboutData();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen">{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center py-10 px-4 bg-transparent text-white">
      <h1 className="text-4xl md:text-5xl font-bold mb-10 text-center">About Us</h1>
      
      <div className="grid grid-cols-1  gap-8 w-full max-w-7xl">
        {about.map((item, index) => (
          <div
            key={index}
            className=" border border-gray-700 rounded-xl shadow-lg overflow-hidden  "
          >
            <img
              src={item.image}
              alt={`About Image ${index + 1}`}
              className="w-full   small:h-48 h-60 object-cover"
            />
            <div className="p-6">
              <h2 className="text-2xl font-bold  mb-4">{item.title}</h2>
              <p className="text-gray-300 text-base leading-relaxed">{item.description}</p>
            </div>
          </div>
        ))}
      </div>

      <Gallery />
    </div>
  );
}
