import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button } from '../../components/ui/button';

interface BannerProps {
  _id: string;
  heading: string;
  description: string;
  image?: {
    url: string;
    public_id: string;
  };
  __v: number;
}

interface BannerFormProps {
  currentBanner?: BannerProps | null;
  onSubmit: (data: BannerFormData) => void;
  onClose: () => void;
}

interface BannerFormData {
  heading: string;
  description: string;
  image?: FileList;
}

const BannerForm: React.FC<BannerFormProps> = ({ currentBanner, onSubmit, onClose }) => {
  const { register, handleSubmit, reset, setValue } = useForm<BannerFormData>();

  useEffect(() => {
    if (currentBanner) {
      setValue('heading', currentBanner.heading);
      setValue('description', currentBanner.description);
    } else {
      reset();
    }
  }, [currentBanner, setValue, reset]);

  const onSubmitHandler: SubmitHandler<BannerFormData> = (data) => {
    onSubmit(data);
  };

  return (
    <div className="z-20">
      <h2>{currentBanner ? 'Update Banner' : 'Add Banner'}</h2>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-4">
        <div>
          <label htmlFor="heading">Title</label>
          <input
            id="heading"
            {...register('heading', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            {...register('description', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            {...register('image')}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <Button type="submit">
          {currentBanner ? 'Update' : 'Add'}
        </Button>
        <Button type="button" onClick={onClose} variant="outline">
          Cancel
        </Button>
      </form>
    </div>
  );
};

export default BannerForm;
