// ChannelsPage.tsx

import React, { useState, useEffect } from 'react';
import AdminLayout from './layout';
import ChannelCard from '@/components/admin/ChannelCard';
import ChannelForm from '@/components/admin/ChannelForm';
import axios from 'axios';

interface Channel {
  _id: string;
  image: string;
  description: string;
}

const LandingPage: React.FC = () => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [channelToUpdate, setChannelToUpdate] = useState<string | null>(null);


  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axios.get<Channel[]>(`${import.meta.env.VITE_API_KEY}/channel`);
        setChannels(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching channels');
        setLoading(false);
      }
    };

    fetchChannels();
  }, []);

  const handleUpdateChannel = (channelId: string) => {
    setChannelToUpdate(channelId);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setChannelToUpdate(null);
  };

  const handleDeleteChannel = async (channelId: string)=> {
    try {
      await axios.delete(`${import.meta.env.VITE_API_KEY}/channel/${channelId}`);
      const updatedChannels = channels.filter((channel) => channel._id !== channelId);
      setChannels(updatedChannels);
    } catch (error) {
      console.error('Error deleting channel:', error);
    }
  };

  const handleSubmitSuccess = () => {
    const fetchUpdatedChannels = async () => {
      try {
        const response = await axios.get<Channel[]>(`${import.meta.env.VITE_API_KEY}/channel`);
        setChannels(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching channels');
        setLoading(false);
      }
    };
    fetchUpdatedChannels();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <AdminLayout>
      <div className="flex flex-col w-full h-full text-white items-center space-y-4">
        <div className="w-full flex flex-wrap">
          {channels.map((channel) => (
            <ChannelCard
              key={channel._id}
              channel={channel}
              onUpdate={() => handleUpdateChannel(channel._id)}
              onDelete={handleDeleteChannel} 
            />
          ))}
        </div>
      </div>

      <ChannelForm
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onSubmitSuccess={handleSubmitSuccess}
        initialValues={channelToUpdate ? channels.find((c) => c._id === channelToUpdate) : undefined}
        channelIdToUpdate={channelToUpdate || undefined}
      />
    </AdminLayout>
  );
};

export default LandingPage;
