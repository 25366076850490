import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../components/ui/card';
import { Button } from '../../components/ui/button';

interface ImageProps {
  url: string;
  public_id: string;
}

interface GalleryItemProps {
  _id: string;
  heading: string;
  description: string;
  image?: ImageProps;
  __v: number;
}

interface GalleryCardProps {
  galleryItem: GalleryItemProps;
  onUpdate: (galleryItem: GalleryItemProps) => void;
  onDelete: (id: string) => void;
}

const GalleryCard: React.FC<GalleryCardProps> = ({ galleryItem, onUpdate, onDelete }) => {
  return (
    <Card key={galleryItem._id} className="w-full h-auto text-white max-w-md m-2">
      {galleryItem.image && <img src={galleryItem.image.url} alt={galleryItem.heading} className="rounded-t-lg" />}
      <CardHeader>
        <CardTitle>{galleryItem.heading}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription>{galleryItem.description}</CardDescription>
        <div className="flex justify-between mt-4">
          <Button onClick={() => onUpdate(galleryItem)} variant="outline">
            Update
          </Button>
          <Button onClick={() => onDelete(galleryItem._id)} variant="destructive" className="bg-black text-white">
            Delete
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default GalleryCard;
