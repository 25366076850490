
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm as useReactHookForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { useForm as useFormspree } from '@formspree/react';
import { Button } from "@/components/ui/button";
import Success from "@/components/success";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

const formSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
  message: z.string().min(10, {
    message: "Message must be at least 10 characters.",
  }),
});

type FormData = z.infer<typeof formSchema>;

export function ContactPage() {
  const form = useReactHookForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  const [state, handleSubmit] = useFormspree("xyzgglov");

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await handleSubmit(data); // Assuming handleSubmit handles the submission internally

      // Since handleSubmit doesn't return a response, assume submission succeeded
      form.reset(); // Reset form on successful submission
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  if (state.succeeded) {
    return <Success />;
  }

  return (
    <div className="p-8 bg-transparent my-10 small:text-sm w-full flex flex-col items-center  rounded-lg shadow-md">
      <h1 className="text-3xl text-green-300 mid:text-xl font-bold mb-4">Contact Us</h1>
      <p className="mb-6 mid:text-[12px]  text-center text-white font-bold">
        We'd love to hear from you! Please fill out the form below and we'll get in touch with you shortly.
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 w-2/3 small:w-full text-green-100">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input className="bg-black" placeholder="Prashant Bhusal" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input className="bg-black" placeholder="nepcodedottech.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <Textarea className="text-green-200 text-sm bg-black h-20" placeholder="Your message..." {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full small:h-8 small:text-xl rounded-full bg-white text-2xl text-black">
            Submit
          </Button>
        </form>
      </Form>
    </div>
  );
}

export default ContactPage;
