// src/components/admin/AboutPageCard.tsx

import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button } from '../../components/ui/button';
import AboutPageForm from './AboutPageForm'; 
import axios from 'axios';


interface AboutPageCardProps {
  aboutPage: {
    _id: string;
    image: string;
    title: string;
    description: string;
    position: number;
  };
  onUpdate: () => void;
  onDelete: () => void;
}

const AboutPageCard: React.FC<AboutPageCardProps> = ({ aboutPage, onUpdate, onDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${import.meta.env.VITE_API_KEY}/about/${aboutPage._id}`);
      onDelete();
    } catch (error) {
      console.error('Error deleting about page:', error);
    }
  };

  return (
    <div className=" border flex flex-wrap w-96 h-auto min-h-96  rounded-lg text-white shadow-md p-4 mb-4">
      <img src={aboutPage.image} alt={aboutPage.title} className="w-full h-40 object-cover mb-4 rounded-lg" />
      <h3 className="text-lg font-bold mb-2">{aboutPage.title}</h3>
      <p className="text-white mb-2">{aboutPage.description}</p>
      <div className="flex justify-between items-center">
        <Button onClick={openModal}>
          Update
        </Button>
        <Button onClick={handleDelete} >
          Delete
        </Button>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="z-10">
        {/* Render the AboutPageForm inside the modal for updating */}
        <AboutPageForm
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmitSuccess={onUpdate}
          initialValues={{
            image: aboutPage.image,
            title: aboutPage.title,
            description: aboutPage.description,
            position: aboutPage.position,
          }}
          aboutPageIdToUpdate={aboutPage._id}
        />
      </Modal>
    </div>
  );
};

export default AboutPageCard;
