import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../store/useAuthStore';

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const {admin}=useAuthStore();
  

    if (!admin) {
        return <Navigate to="/"/>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
