import React, { useEffect, useState } from 'react';
import AdminLayout from './layout';
import Modal from 'react-modal';
import { Button } from '../components/ui/button';
import axios from 'axios';

interface FAQItemProps {
  _id: string;
  question: string;
  answer: string;
  place: number;
  __v: number;
}

const FAQ: React.FC = () => {
  const [faqItems, setFAQItems] = useState<FAQItemProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentFAQItem, setCurrentFAQItem] = useState<FAQItemProps | null>(null);


  useEffect(() => {
    const fetchFAQItems = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_KEY}/faq`); // Adjust the URL to your API endpoint
        setFAQItems(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch FAQ items');
        setLoading(false);
      }
    };

    fetchFAQItems();
  }, []);

  const openUpdateModal = (faqItem: FAQItemProps) => {
    setCurrentFAQItem(faqItem);
    setModalIsOpen(true);
  };

  const handleUpdate = async (data: any) => {
    if (!currentFAQItem) return;

    try {
      await axios.put(`${import.meta.env.VITE_API_KEY}/faq/${currentFAQItem._id}`, {
        question: data.question,
        answer: data.answer,
        place: parseInt(data.place),
      });

      const response = await axios.get(`${import.meta.env.VITE_API_KEY}/faq`);
      setFAQItems(response.data);
      setModalIsOpen(false);
      setCurrentFAQItem(null);
    } catch (error) {
      console.error('Error updating FAQ item:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${import.meta.env.VITE_API_KEY}/faq/${id}`);
      const response = await axios.get(`${import.meta.env.VITE_API_KEY}/channel`);
      setFAQItems(response.data);
    } catch (error) {
      console.error('Error deleting FAQ item:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const data = {
      question: formData.get('question') as string,
      answer: formData.get('answer') as string,
      place: parseInt(formData.get('place') as string),
    };

    if (currentFAQItem) {
      handleUpdate(data);
    } else {
      try {
        await axios.post(`${import.meta.env.VITE_API_KEY}/faq`, {
          question: data.question,
          answer: data.answer,
          place: data.place,
        });

        const response = await axios.get(`${import.meta.env.VITE_API_KEY}/faq`);
        setFAQItems(response.data);
        setModalIsOpen(false);
      } catch (error) {
        console.error('Error adding FAQ item:', error);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <AdminLayout>
      <div className="flex flex-col w-full h-full text-white items-center space-y-4">
        <Button onClick={() => setModalIsOpen(true)}  className="bg-white text-black">
          Add FAQ Item
        </Button>
        <div className="w-full justify-center flex flex-wrap">
          {faqItems.map((faqItem) => (
            <div key={faqItem._id} className="w-full max-w-md m-2 p-4 bg-gray-800 rounded">
              <h2 className="text-xl">{faqItem.question}</h2>
              <p>{faqItem.answer}</p>
              <p className="text-sm text-gray-400">Place: {faqItem.place}</p>
              <div className="flex justify-end mt-4">
                <Button onClick={() => openUpdateModal(faqItem)} variant="outline">
                  Update
                </Button>
                <Button onClick={() => handleDelete(faqItem._id)} variant="destructive" className="ml-2">
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setCurrentFAQItem(null);
        }}
        contentLabel="Add or Update FAQ Item Modal"
        className="z-10"
      >
        <div className="z-20">
          <h2>{currentFAQItem ? 'Update FAQ Item' : 'Add FAQ Item'}</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="question">Question</label>
              <input
                id="question"
                type="text"
                required
                className="block w-full p-2 border border-gray-300 rounded"
                defaultValue={currentFAQItem ? currentFAQItem.question : ''}
                name="question"
              />
            </div>
            <div>
              <label htmlFor="answer">Answer</label>
              <textarea
                id="answer"
                required
                className="block w-full p-2 border border-gray-300 rounded"
                defaultValue={currentFAQItem ? currentFAQItem.answer : ''}
                name="answer"
              />
            </div>
            <div>
              <label htmlFor="place">Place</label>
              <input
                id="place"
                type="number"
                required
                className="block w-full p-2 border border-gray-300 rounded"
                defaultValue={currentFAQItem ? currentFAQItem.place : ''}
                name="place"
              />
            </div>
            <Button type="submit" className="bg-black text-white">
              {currentFAQItem ? 'Update' : 'Add'}
            </Button>
            <Button type="button" onClick={() => setModalIsOpen(false)} variant="outline">
              Cancel
            </Button>
          </form>
        </div>
      </Modal>
    </AdminLayout>
  );
};

export default FAQ;
