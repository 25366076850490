import React, { useState, useEffect } from "react";
import { Helmet} from "react-helmet";
import PlaylistPage from "./PlaylistPage";
import VideosPage from "./VideosPage";

const Course: React.FC = () => {
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(null);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (selectedPlaylistId) {
        event.preventDefault();
        event.returnValue = ""; // For Chrome
        return ""; // For other browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedPlaylistId]);

  return (
    <>
      <Helmet>
        <title>{selectedPlaylistId ? "Watch Video - IT Education Nepal" : "Select a Playlist - IT Education Nepal"}</title>
        <meta
          name="description"
          content={selectedPlaylistId ? "Watch selected video from the IT Education Nepal playlist." : "Browse through various IT-related playlists and select one to start learning."}
        />
        <meta name="keywords" content="IT Education, Free Courses, IT Nepal, Programming, Web Development, IT Playlists" />
      </Helmet>

      <div className="w-screen flex">
        {selectedPlaylistId ? (
          <VideosPage playlistId={selectedPlaylistId} />
        ) : (
          <PlaylistPage onSelectPlaylist={setSelectedPlaylistId} />
        )}
      </div>
    </>
  );
};

export default Course;
