import React, { useEffect, useState } from 'react';
import AdminLayout from './layout';
import Modal from 'react-modal';
import GalleryForm from '../components/admin/GalleryForm';
import GalleryCard from '../components/admin/GalleryCard';
import { Button } from '../components/ui/button';
import axios from 'axios';


interface ImageProps {
  url: string;
  public_id: string;
}

export interface GalleryItemProps {
  _id: string;
  heading: string;
  description: string;
  image?: ImageProps;
  __v: number;
}

interface FormDataProps {
  heading: string;
  description: string;
  image?: FileList;
}

const Gallery: React.FC = () => {
  const [galleryItems, setGalleryItems] = useState<GalleryItemProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentGalleryItem, setCurrentGalleryItem] = useState<GalleryItemProps | null>(null);


  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await axios.get<GalleryItemProps[]>(`${import.meta.env.VITE_API_KEY}/gallery`);
        setGalleryItems(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch gallery items');
        setLoading(false);
      }
    };

    fetchGalleryItems();
  }, []);

  const openUpdateModal = (galleryItem: GalleryItemProps) => {
    setCurrentGalleryItem(galleryItem);
    setModalIsOpen(true);
  };

  const handleUpdate = async (data: FormDataProps) => {
    if (!currentGalleryItem) return;

    try {
      const formData = new FormData();
      formData.append('heading', data.heading);
      formData.append('description', data.description);
      if (data.image && data.image[0]) {
        formData.append('image', data.image[0]);
      }

      await axios.put(`${import.meta.env.VITE_API_KEY}/gallery/${currentGalleryItem._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const response = await axios.get<GalleryItemProps[]>(`${import.meta.env.VITE_API_KEY}/gallery`);
      setGalleryItems(response.data);
      setModalIsOpen(false);
      setCurrentGalleryItem(null);
    } catch (error) {
      console.error('Error updating gallery item:', error);
      setError('Error updating gallery item'); // Update error state on error
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${import.meta.env.VITE_API_KEY}/gallery/${id}`);
      const response = await axios.get<GalleryItemProps[]>(`${import.meta.env.VITE_API_KEY}/gallery`);
      setGalleryItems(response.data);
    } catch (error) {
      console.error('Error deleting gallery item:', error);
      setError('Error deleting gallery item'); // Update error state on error
    }
  };

  const handleSubmit = async (data: FormDataProps) => {
    if (currentGalleryItem) {
      handleUpdate(data);
    } else {
      try {
        const formData = new FormData();
        formData.append('heading', data.heading);
        formData.append('description', data.description);
        if (data.image && data.image[0]) {
          formData.append('image', data.image[0]);
        }

        await axios.post(`${import.meta.env.VITE_API_KEY}/gallery`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const response = await axios.get<GalleryItemProps[]>(`${import.meta.env.VITE_API_KEY}/gallery`);
        setGalleryItems(response.data);
        setModalIsOpen(false);
      } catch (error) {
        console.error('Error adding gallery item:', error);
        setError('Error adding gallery item'); // Update error state on error
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <AdminLayout>
      <div className="flex flex-col w-full h-full text-white items-center space-y-4">
        <Button onClick={() => setModalIsOpen(true)} className="bg-white text-black">
          Add Gallery Item
        </Button>
        <div className="w-full justify-center flex flex-wrap">
          {galleryItems.map((galleryItem) => (
            <GalleryCard
              key={galleryItem._id}
              galleryItem={galleryItem}
              onUpdate={openUpdateModal}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>

      <Modal
        className="z-10"
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setCurrentGalleryItem(null);
        }}
        contentLabel="Add or Update Gallery Item Modal"
      >
        <GalleryForm
          currentGalleryItem={currentGalleryItem}
          onSubmit={handleSubmit}
          onClose={() => setModalIsOpen(false)}
        />
      </Modal>
    </AdminLayout>
  );
};

export default Gallery;
