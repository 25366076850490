import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/useAuthStore';
import { Button } from '../components/button';
import axios from 'axios';

interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();

  const { setToken } = useAuthStore();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_KEY}/admin/login`, {
        email: data.email,
        password: data.password,
      });

      console.log('Login successful:', response.data);
      setToken(response.data.token);

      navigate('/admin');
    } catch (error) {
      console.error('Login error:', error);
      // Handle login error (show error message, etc.)
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-transparent">
      <div className="max-w-md w-full text-white bg-black p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Login</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label htmlFor="email" className="block mb-1">
              Email
            </label>
            <input
              id="email"
              type="email"
              {...register('email', { required: 'Email is required' })}
              className="w-full p-2 border bg-slate-800 text-white border-none outline-none rounded"
            />
            {errors.email && <p className="text-red-500 mt-1">{errors.email.message}</p>}
          </div>
          <div>
            <label htmlFor="password" className="block mb-1">
              Password
            </label>
            <input
              id="password"
              type="password"
              {...register('password', { required: 'Password is required' })}
              className="w-full p-2 border bg-slate-800 text-white border-none outline-none rounded"
            />
            {errors.password && <p className="text-red-500 mt-1">{errors.password.message}</p>}
          </div>
          <Button
            type="submit"
            variant={'outline'}
            className="w-full bg-blue-4 cursor-pointer text-white p-2 rounded"
          >
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
