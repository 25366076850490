import React, { useEffect, useState } from 'react';
import AdminLayout from './layout';
import Modal from 'react-modal';
import BannerForm from '../components/admin/BannerForm';
import BannerCard from '../components/admin/BannerCard';
import { Button } from '../components/ui/button';
import axios from 'axios';

interface BannerProps {
  _id: string;
  heading: string;
  description: string;
  image?: {
    url: string;
    public_id: string;
  };
  __v: number;
}

const Banner: React.FC = () => {
  const [banners, setBanners] = useState<BannerProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentBanner, setCurrentBanner] = useState<BannerProps | null>(null);


  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_KEY}/banners`); 
        setBanners(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch banners');
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  const openUpdateModal = (banner: BannerProps) => {
    setCurrentBanner(banner);
    setModalIsOpen(true);
  };

  const handleUpdate = async (data: any) => {
    if (!currentBanner) return;

    try {
      const formData = new FormData();
      formData.append('heading', data.heading);
      formData.append('description', data.description);
      if (data.image[0]) {
        formData.append('image', data.image[0]);
      }

      await axios.put(`${import.meta.env.VITE_API_KEY}/banners/${currentBanner._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const response = await axios.get(`${import.meta.env.VITE_API_KEY}/banners`);
      setBanners(response.data);
      setModalIsOpen(false);
      setCurrentBanner(null);
    } catch (error) {
      console.error('Error updating banner:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${import.meta.env.VITE_API_KEY}/banners/${id}`);
      const response = await axios.get(`${import.meta.env.VITE_API_KEY}/banners`);
      setBanners(response.data);
    } catch (error) {
      console.error('Error deleting banner:', error);
    }
  };

  const handleSubmit = async (data: any) => {
    if (currentBanner) {
      handleUpdate(data);
    } else {
      try {
        const formData = new FormData();
        formData.append('heading', data.heading);
        formData.append('description', data.description);
        if (data.image[0]) {
          formData.append('image', data.image[0]);
        }

        await axios.post(`${import.meta.env.VITE_API_KEY}/banners`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const response = await axios.get(`${import.meta.env.VITE_API_KEY}/banners`);
        setBanners(response.data);
        setModalIsOpen(false);
      } catch (error) {
        console.error('Error adding banner:', error);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <AdminLayout>
      <div className="flex flex-col w-full h-full text-white items-center space-y-4">
        <Button onClick={() => setModalIsOpen(true)}  className="bg-white text-black">
          Add Banner
        </Button>
        <div className="w-full justify-center flex flex-wrap">
          {banners.map((banner) => (
            <BannerCard key={banner._id} banner={banner} onUpdate={openUpdateModal} onDelete={handleDelete} />
          ))}
        </div>
      </div>

      <Modal
      className="z-10"
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setCurrentBanner(null);
        }}
        contentLabel="Add or Update Banner Modal"
      >
        <BannerForm currentBanner={currentBanner} onSubmit={handleSubmit} onClose={() => setModalIsOpen(false)} />
      </Modal>
    </AdminLayout>
  );
};

export default Banner;
