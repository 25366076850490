import { Link } from "react-router-dom";
import { Button } from '../components/button';
import Video from "../../public/Untitled design (3).mp4"
// import { useEffect, useState } from "react";
// import { axiosInstance } from "@/utils/axiosinstance";
// // import axios from "axios";
// interface Channel {
//   image: string;
//   description:string;
// }

const ChannelInfo = () => {
  // const [channel, setChannel] = useState<Channel | null>(null);
  // const AxiosIns=axiosInstance("")
  // useEffect(() => {
  //   const fetchChannel = async () => {
  //     try {
  //       const response = await axios.get(`${import.meta.env.VITE_API_KEY}/channel`);
  //       setChannel(response.data[0]);
  //     } catch (error) {
  //       console.error("Error fetching the channel data", error);
  //     }
  //   };

  //   fetchChannel();
  // }, []);

  // if (!channel) return <div>Loading...</div>;

  return (
    <div className=' h-screen  border-b-4 border-green-800  w-full flex flex-col items-center'>
      <div className='w-5/6 flex mid:flex-col flex-row-reverse  justify-around items-center h-3/4'>
      <div className='mt-8 w-1/2 mid:w-5/6 h-[90vh]  bg-black small:w-screen border-none small:flex small:justify-center small:items-center  py-4 rounded-3xl max-w-4xl'>
            <video className='w-full small:w-10/12 border-none  h-full ' src={Video} autoPlay muted loop controls={false} />
          </div>
        <div className=' flex flex-col w-1/2 mid:w-full channel h-[90vh]  justify-center items-center small:w-full small:gap-0  mid:gap-4  gap-10  md:mt-0'>
          <p className='text-white mt-4  small:text-[16px] font-semibold  small:leading-6 text-center font-italic  pt-4 text-4xl leading-snug '>
       
        Empowering the next generation of IT leaders, at <span className="text-green-300 font-bold">IT Education Nepal</span>, we're building brighter futures through knowledge and innovation
          </p>
          
          {/* Utilize the Button component here */}
          <div className='flex justify-center'>
            <Link to={'/courses'}>
              <Button className=' bg-green-400 hover:bg-green-100 ease-in font-mono   small:w-28 small:text-sm small:h-10 my-3 h-12 text-black  text-3xl ' variant='default'>
                View Courses
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelInfo;
