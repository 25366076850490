import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../ui/button';

interface ChannelCardProps {
  channel: {
    _id: string;
    image: string;
    description: string;
  };
  onUpdate: () => void;
  onDelete: (channelId: string) => void; // Updated onDelete to accept channelId
}

const ChannelCard: React.FC<ChannelCardProps> = ({ channel, onUpdate, onDelete }) => {
  const handleDelete = () => {
    onDelete(channel._id); // Call onDelete with channelId when delete button is clicked
  };

  return (
    <Card className="w-full h-auto text-white max-w-md m-2">
      <img src={channel.image} alt="Channel" className="rounded-t-lg" />
      <CardHeader>
        <CardTitle>{channel.description}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between mt-4">
          <Button onClick={onUpdate} variant="outline">
            Update
          </Button>
          <Button onClick={handleDelete} variant="outline"> {/* Use handleDelete to handle deletion */}
            Delete
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default ChannelCard;
