

import React, { useState } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/ui/button';
import axios from 'axios';

interface ChannelFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
  initialValues?: {
    image: string;
    description: string;
  };
  channelIdToUpdate?: string;
}

const ChannelForm: React.FC<ChannelFormProps> = ({
  isOpen,
  onClose,
  onSubmitSuccess,
  initialValues = { image: '', description: '' },
  channelIdToUpdate,
}) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      if (selectedFile) {
        formData.append('image', selectedFile);
      }
      formData.append('description', data.description);

      if (channelIdToUpdate) {
        await axios.put(`${import.meta.env.VITE_API_KEY}/channel/${channelIdToUpdate}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await axios.post(`${import.meta.env.VITE_API_KEY}/channel`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      onSubmitSuccess();
      reset();
      onClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={channelIdToUpdate ? 'Update Channel' : 'Add Channel'}
      className="z-20"
    >
      <h2>{channelIdToUpdate ? 'Update Channel' : 'Add Channel'}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="image">Upload Image</label>
          <input
            id="image"
            type="file"
            onChange={handleFileChange}
            className="block w-full p-2 border border-gray-300 rounded"
            accept="image/*"
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            {...register('description', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <Button type="submit">
          {channelIdToUpdate ? 'Update' : 'Add'}
        </Button>
      </form>
    </Modal>
  );
};

export default ChannelForm;
