import  { useState, useEffect } from 'react';
import "../App.css";
import Team from "../components/Team";
import FAQ from "../components/FAQ";
import ChannelInfo from "@/components/ChannelInfo";
import Video from "../../public/Untitled video - Made with Clipchamp.mp4";
import axios from 'axios';

const Homepage = () => {
  const [channelStats, setChannelStats] = useState({
    subscriberCount: 0,
    videoCount: 0,
  });

  useEffect(() => {
    const fetchChannelStats = async () => {
      try {
        const response = await axios.get(
          'https://www.googleapis.com/youtube/v3/channels',
          {
            params: {
              part: 'statistics',
              id: 'UCJinKAyRTpHJOPE0YgCMhog',
              key: 'AIzaSyAyFenBbqFFtTG3-vmSLjBbi8Jt1cCPaJk',
            },
          }
        );
        const data = response.data.items[0].statistics;
        setChannelStats({
          subscriberCount: data.subscriberCount,
          videoCount: data.videoCount,
        });
      } catch (error) {
        console.error('Error fetching channel statistics:', error);
      }
    };

    fetchChannelStats();
  }, []);
  const formatSubscriberCount = (count:number) => {
    const roundedCount = Math.floor(count / 100) * 100;
    return `${roundedCount}`;
  };
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <ChannelInfo />

        <div className="flex justify-evenly channel bg-black border-b-4 border-green-700    items-center  h-96 w-5/6">
          <div className="text-white flex flex-col items-center justify-center w-60 h-40 rounded-xl shadow-lg text-center">
            <h2 className="text-5xl mid:text-xl font-bold">{formatSubscriberCount(channelStats.subscriberCount)}<span className='text-green-400'>+</span></h2>
            <p className="text-2xl mid:text-sm">Subscribers</p>
          </div>
          <div className="text-white flex flex-col items-center justify-center w-60 h-40 rounded-xl shadow-lg text-center">
            <h2 className="text-5xl mid:text-xl font-bold">{formatSubscriberCount(channelStats.videoCount)}<span className='text-green-400'>+</span></h2>
            <p className="text-2xl mid:text-sm">Videos</p>
          </div>
          <div className="text-white flex flex-col items-center justify-center w-60 h-40 rounded-xl shadow-lg text-center">
            <h2 className="text-5xl mid:text-xl font-bold">20<span className='text-green-400'>+</span></h2>
            <p className="text-2xl mid:text-sm">Teachers</p>
          </div>
        </div>

        <div className="flex mid:flex-col channel justify-around bg-black border-b-4 border-green-700  items-center w-5/6">
          <div className='text-center small:w-full w-82'>
            <h1 className='text-5xl py-4 small:text-sm mid:text-xl  font-bold text-green-400'>Learning Platform</h1>
            <p className='mt-4 text-3xl py-1 px-4   mid:text-sm text-white'>Explore our curated courses from YouTube and enhance your knowledge.</p>
          </div>

          <div className='mt-8 w-1/2 small:w-screen small:flex small:justify-center small:items-center h-full py-4 rounded-3xl max-w-4xl'>
            <video className='w-full small:w-10/12  h-full rounded-lg shadow-lg' src={Video} autoPlay muted loop controls={false} />
          </div>
        </div>

        <Team />
        <FAQ />
      </div>
    </>
  );
}

export default Homepage;
