import { Button } from '../button';
import { faFacebook, faTelegram, faWhatsapp, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../../public/logo.jpg'
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <div className='bg-transparent  border-t-4 border-green-800 flex flex-col  items-center'>
        {/* <div className="bg-blue-300 p-6 rounded-lg w-5/6">
          <h2 className="text-3xl font-bold mb-4">Learn These Things</h2>
          <p className="text-xl font-semibold mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero sed neque commodo, non aliquet sapien vehicula. Sed dictum dolor a velit scelerisque, ut dictum lorem lacinia.</p>
          <Button className='bg-white ' variant="secondary">Explore</Button>
        </div> */}
    <footer className=" flex py-12 w-screen mid:flex-wrap justify-evenly ">
      <div className=" mx-auto px-4 mb-8 mid:w-full mid:justify-center  text-white flex  ">
        
        <div className="flex flex-col justify-evenly mx-10">
          <div className=''>
            <div className='w-28 h-28 rounded-full bg-blue-300'>

            <img src={Logo}  className=" mb-2" />
            </div>
            <p className="text-md py-3 text-green-400 font-bold "> <span className='text-white text-xl'>©</span> ITEducationNepal</p>
          </div>

          <div className="flex items-center space-x-4">
            <a href="https://www.facebook.com/groups/iteducationnepal/" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600">
              <FontAwesomeIcon icon={faFacebook} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://t.me/iteducationnepal" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600">
              <FontAwesomeIcon icon={faTelegram} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600">
              <FontAwesomeIcon icon={faWhatsapp} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600">
              <FontAwesomeIcon icon={faLinkedin} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://www.youtube.com/@iteducationnepal-6725" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600">
              <FontAwesomeIcon icon={faYoutube} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
          </div>
        </div>
      </div>

      {/* Links section */}
      <div className=" mid:hidden py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-bold text-green-400 mb-4">Links</h3>
              <ul className='text-sm flex flex-col gap-2'>
                <li><a href="/" className="text-gray-300 hover:text-white">Home</a></li>
                <li><a href="/about" className="text-gray-300 hover:text-white">About</a></li>
                <li><a href="/courses" className="text-gray-300 hover:text-white">Courses</a></li>
                <li><a href="/contact" className="text-gray-300 hover:text-white">Contact</a></li>
              </ul>
            </div>
            {/* Add more link sections as needed */}
          </div>
        </div>
      </div>

      {/* Get in touch and buttons */}
      <div className="container mx-auto px-4 mt-8 flex justify-around">
        <div>
          <h3 className="text-green-400 font-bold mb-4">Get in Touch</h3>
          <p className="text-gray-300 mb-4">123 Main Street<br />City, Country</p>
          <a className='bg-green-400  p-2 rounded-md ' href="mailto:contact@iteducationnepal.org" >Email Us</a>
        </div>
        <div>
          <h3 className="text-green-400 font-bold mb-4">Facing Any Issue?</h3>
          <Link to="/contact">
          <Button className='bg-green-400' variant="secondary">Report Issue</Button>
          </Link>
        </div>
      </div>
    </footer>
    {/* <div className='w-screen h-28 flex justify-center  gap-x-3  text-white items-center '>
        <div className='text-sm'>
          credits :
        </div>
        <a className='text-green-400 text-center   cursor-pointer px-2 text-bold text-sm text'>
          Prashant Bhusal
        </a> <span>,</span>
        <a className='text-green-400 text-center  cursor-pointer px-2 text-bold text-sm text'>
          Anil Bhandari
        </a>
    </div> */}
    </div>
  );
}

export default Footer;
