import React, { ReactNode } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/ui/Footer';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
   <>
      <Navbar />
      <div>{children}</div>
      <Footer />
      </>
  );
};

export default Layout;
