import { useState, useEffect } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import axios from "axios";
// Define interface for TeamMember
interface TeamMember {
  _id: string;
  name: string;
  experience: string;
  title: string;
  image: {
    url: string;
    public_id: string;
  };
}

const Team = () => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get<TeamMember[]>(`${import.meta.env.VITE_API_KEY}/team`); // Adjust the endpoint as per your backend API
        setTeamMembers(response.data);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchTeamMembers();
  }, []);

  return (
    <div className="w-5/6  bg-black flex  justify-center  border-b-4 border-green-700  items-center flex-col h-[500px]">
      {/* <h1 className="text-white text-4xl font-bold py-8">Meet our team</h1> */}

      <Carousel
       
      className="w-10/12 bg-transparent  cursor-grab border-none rounded-3xl">
        <CarouselContent>
          {teamMembers.map((member) => (
            <CarouselItem key={member._id} className="basis-78">
              <div className="p-1">
                <Card className="w-72 small:w-48 small:h-80 border border-green-900 h-96">
                  <CardHeader className="p-0 flex justify-center items-center mt-3">
                    <img
                      src={member.image.url}
                      alt={member.name}
                      className="w-48 h-48 small:w-28 small:h-28 object-cover rounded-full"
                    />
                  </CardHeader>
                  <CardContent className="flex items-center justify-center p-6">
                    <div>
                      <CardTitle className="text-xl small:text-sm text-green-400 font-bold">
                        {member.name}
                      </CardTitle>
                      <CardDescription className="text-white small:text-[10px] small:leading-3 font-semibold mt-2">
                        {member.title} - {member.experience} years of experience
                      </CardDescription>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
}

export default Team;
