import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LandingPage from './admin/LandingPage';
import './index.css';
import AboutPage from './pages/AboutPage';
import { ContactPage } from './pages/ContactPage';
import Course from './pages/Course';
import Banner from './admin/Banner';
import FAQ from './admin/FAQ';
import Team from './admin/Team';
import Gallery from './admin/Gallery';

import Login from './admin/Login';
import About from './admin/About';
import Layout from './app';

// Define routes
const routes = createRoutesFromElements(
  <>
    <Route path="/" element={<Layout><HomePage /></Layout>} />
    <Route path="/about" element={<Layout><AboutPage /></Layout>} />
    <Route path="/contact" element={<Layout><ContactPage /></Layout>} />
    <Route path="/courses" element={<Layout><Course /></Layout>} />
    <Route path="/adminlogin" element={<Layout><Login /></Layout>} />
    <Route path="/admin" element={<Layout><LandingPage /></Layout>} />
    <Route path="/admin/channel" element={<Layout><LandingPage /><LandingPage/></Layout>} />
    <Route path="/admin/banner" element={<Layout><Banner /></Layout>} />
    <Route path="/admin/faq" element={<Layout><FAQ /></Layout>} />
    <Route path="/admin/team" element={<Layout><Team /></Layout>} />
    <Route path="/admin/gallery" element={<Layout><Gallery /></Layout>} />
    <Route path="/admin/about" element={<Layout><About /></Layout>} />
  </>
);

const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById('root')!).render(
  
  <React.StrictMode>
    <RouterProvider router={router} >
   
    </RouterProvider>
  </React.StrictMode>
);
