import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTelegram, faWhatsapp, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '@/store/useAuthStore';
import Logo from '../../public/logo.jpg';

const Navbar: React.FC = () => {
  const { admin } = useAuthStore();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <nav className="shadow-3xl border-b border-slate-800 bg-black  text-white z-50 sticky top-0">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <Link to="/">
            <div className="w-16 h-16 rounded-full bg-blue-300">
              <img src={Logo} className="mb-2" alt="Logo" />
            </div>
          </Link>
          <button onClick={toggleMenu} className="md:hidden text-white hover:text-gray-200 focus:outline-none">
            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} className="w-6 h-6" />
          </button>
          <div className="hidden md:flex items-center justify-center">
            <div className="flex gap-8 text-white font-bold items-center">
              <Link to="/" className={`cursor-pointer font-bold ${isActive('/') ? 'border-b-4 border-green-300 text-green-300 rounded-sm' : 'hover:border-b-4 rounded-sm border-green-200'}`}>Home</Link>
              <Link to="/about" className={`cursor-pointer font-bold ${isActive('/about') ? 'border-b-4 border-green-300 text-green-300 rounded-sm' : 'hover:border-b-4 rounded-sm border-green-200'}`}>About</Link>
              <Link to="/courses" className={`cursor-pointer font-bold ${isActive('/courses') ? 'border-b-4 border-green-300 text-green-300 rounded-sm' : 'hover:border-b-4 rounded-sm border-green-200'}`}>Courses</Link>
              <Link to="/contact" className={`cursor-pointer font-bold ${isActive('/contact') ? 'border-b-4 border-green-300 text-green-300 rounded-sm' : 'hover:border-b-4 rounded-sm border-green-200'}`}>Contact</Link>
              {admin && (
                <Link to="/admin" className={`cursor-pointer font-bold ${isActive('/admin') ? 'border-b-4 border-green-300 text-green-300 rounded-sm' : 'hover:border-b-4 rounded-sm border-green-200'}`}>Admin</Link>
              )}
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <a href="https://www.facebook.com/groups/iteducationnepal/" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-200">
              <FontAwesomeIcon icon={faFacebook} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://t.me/iteducationnepal" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-200">
              <FontAwesomeIcon icon={faTelegram} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://wa.me/9779866358671" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-200">
              <FontAwesomeIcon icon={faWhatsapp} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://twitter.com/ITEducationNep1" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-200">
              <FontAwesomeIcon icon={faTwitter} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
            <a href="https://www.youtube.com/@iteducationnepal-6725" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-200">
              <FontAwesomeIcon icon={faYoutube} className="w-5 h-5 rounded-full bg-white p-1" />
            </a>
          </div>
        </div>
        <div className={`fixed w-1/2 inset-0 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-black p-4 z-40 md:hidden`}>
          <div className="flex flex-col items-center justify-center space-y-4 mt-4 text-white font-bold">
            <Link to="/" className={`cursor-pointer text-sm py-4  ${isActive('/') ? 'text-green-300' : 'hover:text-green-300'} font-bold`} onClick={toggleMenu}>Home</Link>
            <Link to="/about" className={`cursor-pointer text-sm py-4  ${isActive('/about') ? 'text-green-300' : 'hover:text-green-300'} font-bold`} onClick={toggleMenu}>About</Link>
            <Link to="/courses" className={`cursor-pointer text-sm py-4  ${isActive('/courses') ? 'text-green-300' : 'hover:text-green-300'} font-bold`} onClick={toggleMenu}>Courses</Link>
            <Link to="/contact" className={`cursor-pointer text-sm py-4  ${isActive('/contact') ? 'text-green-300' : 'hover:text-green-300'} font-bold`} onClick={toggleMenu}>Contact</Link>
            {admin && (
              <Link to="/admin" className={`cursor-pointer text-sm py-4  ${isActive('/admin') ? 'text-green-300' : 'hover:text-green-300'} font-bold`} onClick={toggleMenu}>Admin</Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
