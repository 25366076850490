import React, { useEffect } from 'react';
import Sidebar from '@/components/admin/Sidebar';
import ProtectedRoute from '@/utils/ProtectedRoute';
interface AdminLayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Display a confirmation message
      event.returnValue = 'Are you sure you want to leave? Your have to login once again.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <ProtectedRoute>
    <div className="flex">   
      <div className='w-60'>
        <Sidebar />
      </div>
      <div className="flex-1 p-8">
        {children}
      </div>
    </div>
     </ProtectedRoute>
  );
};

export default AdminLayout;
