import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/ui/button';

interface ImageProps {
  url: string;
  public_id: string;
}

interface TeamMemberProps {
  _id: string;
  name: string;
  experience: number;
  title: string;
  image?: ImageProps;
  __v: number;
}

interface FormDataProps {
  name: string;
  experience: number;
  title: string;
  image?: FileList;
}

interface TeamFormProps {
  currentTeamMember?: TeamMemberProps | null;
  onSubmit: (data: FormDataProps) => void;
  onClose: () => void;
}

const TeamForm: React.FC<TeamFormProps> = ({ currentTeamMember, onSubmit, onClose }) => {
  const { register, handleSubmit, reset, setValue } = useForm<FormDataProps>();

  useEffect(() => {
    if (currentTeamMember) {
      setValue('name', currentTeamMember.name);
      setValue('experience', currentTeamMember.experience);
      setValue('title', currentTeamMember.title);
    } else {
      reset();
    }
  }, [currentTeamMember, setValue, reset]);

  return (
    <div className="z-20">
      <h2>{currentTeamMember ? 'Update Team Member' : 'Add Team Member'}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="name">Name</label>
          <input
            id="name"
            {...register('name', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="experience">Experience</label>
          <input
            id="experience"
            type="number"
            {...register('experience', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="title">Title</label>
          <input
            id="title"
            {...register('title', { required: true })}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            {...register('image')}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <Button type="submit" variant="secondary">
          {currentTeamMember ? 'Update' : 'Add'}
        </Button>
        <Button type="button" onClick={onClose} variant="outline">
          Cancel
        </Button>
      </form>
    </div>
  );
};

export default TeamForm;
