import { Button } from './button';
import { Link } from 'react-router-dom'; // If you are using Next.js for navigation

const Success = () => {


  return (
    <div className='w-screen h-screen  flex flex-col justify-center items-center text-center text-blue-200'>
      <h1 className='text-5xl font-bold mb-4'>Success!</h1>
      <p className='text-2xl mb-8'>Your form has been submitted successfully.</p>
      <Link to={'/'}>
      <Button
      
        className='bg-white text-black font-bold py-2 px-4 rounded'
      >
        Go Back
      </Button>
      </Link>
    </div>
  );
};

export default Success;
